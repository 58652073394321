import {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import classes from "./BlogList.module.scss";
import BlogItem from "../../components/BlogItem/BlogItem";
import BlogFilterBox from "../../components/BlogFilterBox/BlogFilterBox";
import Pagination from "../../components/UI/Pagination/Pagination";
import {API_BLOG_URL} from "../../constants/API";
import useHttp from "../../hooks/use-http";
import Loading from "../../components/UI/Loading/Loading";
import NotFoundItems from "../../components/UI/NotFoundItems/NotFoundItems";
import {
    splitQueryParams,
    updateDuplicatedUrlParams,
    updateUniqueUrlParams,
    updateDuplicatedApiParams,
    updateUniqueApiParams,
    resetQueryParams,
} from "../../utils/QueryParams";
import calculateOffset from "../../utils/calculateOffset";
import {LIMIT_ITEM_BLOGS} from '../../constants/Common';
import FeaturedBlogItem from "../../components/FeaturedBlogItem/FeaturedBlogItem";
import BlogSearchBox from "../../components/BlogSearchBox/BlogSearchBox";
import BlogDateFilterBox from "../../components/BlogDateFilterBox/BlogDateFilterBox";
import BlogTagFilterBox from "../../components/BlogTagFilterBox/BlogTagFilterBox";

const BlogList = () => {
    const [blogsListRes, setBlogListRes] = useState([]);
    const [currentPage, setCurrentPage] = useState(null);
    const [count, setCount] = useState(null);
    const [searchItem, setSearchItem] = useState(null);
    const [apiQueryParams, setApiQueryParams] = useState(`?limit=${LIMIT_ITEM_BLOGS}`);
    const history = useHistory();
    const {sendRequest, isLoading} = useHttp();

    const splitUrlQueryparams = () => {
        const queryParams = window.location.search;
        const splitedQueryParams = splitQueryParams(queryParams);
        setBlogsStates(splitedQueryParams);
    };

    const setBlogsStates = (params) => {
        params.page ? setCurrentPage(parseInt(params.page)) : setCurrentPage(1);
        params.search ? setSearchItem(params.search) : setSearchItem(null);
        createOffset(params);
    };

    const createOffset = (params) => {
        let offset;
        // if page is not equal 1
        if (params.page) {
            offset = calculateOffset(parseInt(params.page), LIMIT_ITEM_BLOGS);
            delete params.page;
        } // page =1 (page load for first time)
        else {
            offset = calculateOffset(1, LIMIT_ITEM_BLOGS);
        }
        params["offset"] = offset;
        createApiQueryParams(params);
    };

    const createApiQueryParams = (params) => {
        let updatedQueryParams = apiQueryParams;
        for (let key in params) {
            updatedQueryParams = updateUniqueApiParams(
                `${key}`,
                params[key],
                updatedQueryParams
            );
        }
        setApiQueryParams(updatedQueryParams);
        fetchBlogsList(updatedQueryParams);
    };
    const resetPage = () => {
        setCurrentPage(1);
        let offset = calculateOffset(1, LIMIT_ITEM_BLOGS);
        let updatedQueryParams = updateUniqueApiParams(
            "offset",
            offset,
            apiQueryParams
        );
        setApiQueryParams(updatedQueryParams);
        return updatedQueryParams;
    };
    const getNewsListByUniqueFilters = (newkey, newValue) => {
        let updatedQueryParams;
        updatedQueryParams = resetPage();
        // choose new filter
        if (newValue) {
            if (typeof newValue === 'object') {
                for (let key in newValue) {
                    updatedQueryParams = updateUniqueApiParams(
                        key,
                        newValue[key],
                        updatedQueryParams
                    );
                }
            } else {
                updatedQueryParams = updateUniqueApiParams(
                    newkey,
                    newValue,
                    updatedQueryParams
                );
            }
            const urlParams = {
                page: 1,
                [newkey]: newValue,
            };
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
        } else {
            const urlParams = { page: 1 };
            let result = updateUniqueUrlParams(urlParams);
            result = resetQueryParams(newkey, result);
            updateUniqueUrlPage(result);
            updatedQueryParams = resetQueryParams(newkey, updatedQueryParams);
        }

        setApiQueryParams(updatedQueryParams);
        fetchBlogsList(updatedQueryParams);
    };

    const getBlogsNews = (searchItem) => {
        getNewsListByUniqueFilters("search", searchItem);
        setSearchItem(searchItem);
    };
    const updateUniqueUrlPage = (result) => {
        history.push({
            pathname: "/blogs",
            search: result,
        });
    };
    const updateDuplicatedUrlPage = (result) => {
        history.push({
            pathname: "/blogs",
            search: result,
        });
    };
    const getBlogsList = (data) => {
        setBlogListRes(data.results);
        setCount(data.count);
        window.scrollTo(0, 0);
    };

    const getActivatedPage = (page) => {
        if (currentPage !== page) {
            setCurrentPage(page);
            let offset = calculateOffset(page, LIMIT_ITEM_BLOGS);
            let updatedQueryParams = updateUniqueApiParams(
                "offset",
                offset,
                apiQueryParams
            );
            setApiQueryParams(updatedQueryParams);
            const urlParams = {page};
            let result = updateUniqueUrlParams(urlParams);
            updateUniqueUrlPage(result);
            fetchBlogsList(updatedQueryParams);
        }
    };
    const fetchBlogsList = (updatedQueryParams) => {
        setCount(null);
        sendRequest({url: `${API_BLOG_URL}${updatedQueryParams}`}, getBlogsList);
    };

    useEffect(() => {
        splitUrlQueryparams();
        return () => {
            setBlogListRes([]);
        };
    }, []);

    useEffect(() => {
        return history.listen((location) => {
            if (history.action === "POP") {
                splitUrlQueryparams();
            }
        });
    }, []);

    return (
        <section className={`container  flex-grow ${classes.section}`}>
            <div className="row">
                <div className={`col-9 ${classes.title_col}`}>
                    <div className={classes.title}>Blogs</div>
                </div>
                <div className={`col-3 ${classes.search}`}>
                    <BlogSearchBox
                        onSearchNews={getBlogsNews}
                        searchItem={searchItem}
                    />
                </div>
                <div className="col-xl-9 col-12">

                    {blogsListRes && blogsListRes.length !== 0 && !isLoading && (
                        <div
                            className={`row justify-content-lg-start justify-content-center  align-items-center ${classes["min-h"]}`}
                        >
                            {blogsListRes.map((blog, index) => (
                                // <FeaturedBlogItem
                                //     key={blog.id}
                                //     blog={blog}
                                //     className="col-lg-12 col-md-12 col-12"
                                // />
                                <>
                                    <BlogItem
                                        key={blog.id}
                                        blog={blog}
                                        className="col-lg-12 col-md-12 col-12"
                                    />
                                </>
                            ))}
                        </div>
                    )}
                    {isLoading || (blogsListRes.length === 0 && !isLoading) ? (
                        <div
                            className={`row justify-content-center  align-items-center ${classes["min-h"]}`}
                        >
                            {isLoading && <Loading className={classes.loading}/>}
                            {blogsListRes.length === 0 && !isLoading && (
                                <NotFoundItems className={classes["mt-50"]} item="Blog"/>
                            )}
                        </div>
                    ) : (
                        ""
                    )}
                    {count > LIMIT_ITEM_BLOGS && (
                        <Pagination
                            onActivatedPage={getActivatedPage}
                            count={count}
                            limit={LIMIT_ITEM_BLOGS}
                            currentPage={currentPage}
                            noCount={true}
                        />
                    )}
                </div>

                <div className="col-xl-3 col-12">
                <BlogDateFilterBox onFilter={(date) => getNewsListByUniqueFilters("date", date)} />
                <BlogTagFilterBox onFilter={(tag) => getNewsListByUniqueFilters("tags", tag)} />
                </div>
            </div>

        </section>
    );
};

export default BlogList;
