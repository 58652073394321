import { useState } from "react";
import { format, subMonths, startOfMonth, endOfMonth, startOfDay } from "date-fns";
import classes from "./BlogDateFilterBox.module.scss";

const BlogDateFilterBox = ({ onFilter }) => {
    const [showMore, setShowMore] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const generateMonths = (months) => {
        const dates = [];
        for (let i = 0; i < months; i++) {
            dates.push(subMonths(new Date(), i));
        }
        return dates;
    };

    const handleShowMore = () => {
        setShowMore(!showMore);
    };

    const handleDateFilter = (date) => {
        const selectedDateStartOfDay = selectedDate ? startOfDay(selectedDate).getTime() : null;
        const dateStartOfDay = startOfDay(date).getTime();
    
        if (selectedDateStartOfDay && selectedDateStartOfDay === dateStartOfDay) {
            setSelectedDate(null);
            onFilter(null); // Clear filter
        } else {
            setSelectedDate(date);
            const from_date = format(startOfMonth(date), "yyyy-MM-dd");
            const to_date = format(endOfMonth(date), "yyyy-MM-dd");
            onFilter({ from_date, to_date });
        }
    };

    const monthsToShow = showMore ? 24 : 4;
    const months = generateMonths(monthsToShow);

    return (
        <div className={classes.dateFilterBox}>
            <h3>Post Publication Date</h3>
            {months.map((month, index) => (
                <div
                    key={index}
                    onClick={() => handleDateFilter(month)}
                    className={`${classes.dateItem} ${
                        selectedDate && startOfDay(selectedDate).getTime() === startOfDay(month).getTime()
                            ? classes.selected
                            : ""
                    }`}
                >
                    {format(month, "MMMM yyyy")}
                </div>
            ))}
            <div onClick={handleShowMore} className={classes.showMore}>
                {showMore ? "Show Less" : "Show Older"}
            </div>
        </div>
    );
};

export default BlogDateFilterBox;